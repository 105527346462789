let DATA_PROMISE

const headers = {
  Authorization: `Bearer patD2QnVTu4MF2N6q.e2e89273f8502d545e57b5a28e492d88e1f3820fe41de4e4ce1693a5c65849f8`,
}

export default class API {
  static getData() {
    DATA_PROMISE =
      DATA_PROMISE ||
      Promise.all([
        fetch('https://api.airtable.com/v0/appQRDtGayl35x942/Projects?view=Grid%20view', {
          headers,
        }),
        fetch('https://api.airtable.com/v0/appQRDtGayl35x942/Collaborators?view=Grid%20view', {
          headers,
        }),
        fetch('https://api.airtable.com/v0/appQRDtGayl35x942/Awards?view=Grid%20view', {
          headers,
        }),
        fetch('https://api.airtable.com/v0/appQRDtGayl35x942/Publications?view=Grid%20view', {
          headers,
        }),
        fetch('https://api.airtable.com/v0/appQRDtGayl35x942/Contact?view=Grid%20view', {
          headers,
        }),
      ])
        .then((responses) => {
          return Promise.all(responses.map((value) => value.json()))
        })
        .then((results) => {
          const records = new Map()

          for (const data of results) {
            for (const record of data.records) {
              records.set(record.id, record.fields)
            }
          }

          for (const record of records.values()) {
            for (const key in record) {
              if (record.hasOwnProperty(key)) {
                if (record[key] instanceof Array) {
                  const array = record[key]
                  for (const [i, value] of array.entries()) {
                    if (records.has(value)) {
                      array[i] = records.get(value)
                    }
                  }
                } else {
                  if (records.has(record[key])) {
                    record[key] = records.get(record[key])
                  }
                }
              }
            }
          }

          const projects = []
          const collaborators = []
          const awards = []
          const publications = []
          const contact = []

          for (const [i, array] of [projects, collaborators, awards, publications, contact].entries()) {
            for (const record of results[i].records) {
              array.push(record.fields)
            }
          }

          return {
            projects,
            collaborators,
            awards,
            publications,
            contact,
          }
        })

    return DATA_PROMISE
  }
}
