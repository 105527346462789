import '../projects/index.js'

window.customElements.define(
  'damienmortini-home',
  class extends HTMLElement {
    connectedCallback() {
      this._closeRatio = 0

      this.innerHTML = `
      <style>
        @import "src/home/index.css";
      </style>
      <div class="top"></div>
      <div class="bottom"></div>
      <iframe src="src/home/shader-sphere-noise.html" frameborder="0"></iframe>
      <h1>Damien Mortini</h1>
      <p>Technical Consultant – Creative Technologist – VJ</p>
    `
    }

    get closeRatio() {
      return this._closeRatio
    }

    set closeRatio(value) {
      if (this._closeRatio === value) {
        return
      }
      this._closeRatio = value
      this.style.setProperty('--close-ratio', value)
    }
  },
)
