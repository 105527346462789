import Loader from "../../node_modules/dlib/utils/Loader.js";

window.customElements.define("damienmortini-media", class extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
      <style>
        @import "src/media/index.css";
      </style>
      <img class="thumbnail hide">
      <img class="main hide">
    `;
    this.querySelector("style").addEventListener("load", () => {
      this.dispatchEvent(new Event("load"));
    });

    this._thumbnail = this.querySelector(".thumbnail");
    this._main = this.querySelector(".main");
  }

  set thumbnail(value) {
    this._thumbnail.src = value;
  }

  set src(value) {
    if (!value) {
      this._thumbnail.classList.add("hide");
      this._main.classList.add("hide");
      this.classList.add("empty");
      return;
    }
    this.classList.remove("empty");
    if (Loader.get(value)) {
      this._main.classList.remove("hide");
      this._main.src = value;
    } else {
      this._main.classList.add("hide");
      this._thumbnail.classList.remove("hide");
      this._main.src = value;
      Loader.load(value).then(() => {
        if (this._main.src !== value || this.classList.contains("empty")) {
          return;
        }
        this._main.classList.remove("hide");
        this._thumbnail.classList.add("hide");
      });
    }
  }
});
