import LoopElement from '../../node_modules/dlib/customelements/LoopElement.js'

import '../home/index.js'
import '../projects/index.js'
import '../media/index.js'
import '../awards/index.js'
import '../contact/index.js'

import API from '../API.js'

window.customElements.define(
  'damienmortini-main',
  class extends LoopElement {
    constructor() {
      super({ background: true })
    }

    connectedCallback() {
      super.connectedCallback()

      this.style.visibility = 'hidden'

      this.innerHTML = `
      <style>
        @import "src/main/index.css";
      </style>
      <damienmortini-home></damienmortini-home>
      <damienmortini-media></damienmortini-media>
      <div class="content">
        <span class="arrow">↓</span>
        <damienmortini-projects></damienmortini-projects>
        <damienmortini-awards></damienmortini-awards>
        <damienmortini-contact></damienmortini-contact>
      </div>
    `

      this._content = this.querySelector('.content')
      this._arrow = this.querySelector('.arrow')
      this.home = this.querySelector('damienmortini-home')
      this.home.closeRatio = 1

      this.projects = this.querySelector('damienmortini-projects')
      const media = this.querySelector('damienmortini-media')
      this.projects.addEventListener('mediachange', (e) => {
        if (e.detail) {
          media.thumbnail = e.detail.thumbnails.small.url
          media.src = e.detail.thumbnails.large.url
        } else {
          media.src = null
        }
      })

      this.querySelector('style').addEventListener('load', () => {
        this.dispatchEvent(new Event('load'))
      })

      API.getData().then(() => {
        this.style.visibility = ''
        this.play()
      })

      // Dispatch mouse event to iframe
      const onPointerMove = (e) => {
        this.querySelector('damienmortini-home').querySelector('iframe').contentWindow.dispatchEvent(new e.constructor('mousemove', e))
      }
      this._content.addEventListener('pointerdown', onPointerMove)
      this._content.addEventListener('pointermove', onPointerMove)
    }

    update() {
      if (this._content.scrollTop && !this._arrow.classList.contains('hide')) {
        this._arrow.classList.add('hide')
      }
      if (this._content.scrollTop) {
        this.home.closeRatio = Math.min(1, (this._content.scrollTop / this._content.offsetHeight) * 2)
      } else {
        this.home.closeRatio += -this.home.closeRatio * 0.1
      }
    }
  },
)
