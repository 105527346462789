import API from "../API.js";

window.customElements.define("damienmortini-contact", class extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
      <style>
        @import "src/contact/index.css";
      </style>
      <h2>Contact</h2>
      <ul></ul>
    `;
    this.querySelector("style").addEventListener("load", () => {
      this.dispatchEvent(new Event("load"));
    });

    API.getData().then((data) => {
      const ul = this.querySelector("ul");
      for (const contact of data.contact) {
        ul.innerHTML += `
          <li>
            <a href="${contact["Name"] === "Email" ? `mailto:${contact["Link"]}` : contact["Link"]}" target="_blank">${contact["Name"]}</a>
          </li>
        `;
      }
    });
  }
});
