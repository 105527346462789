import API from "../API.js";

window.customElements.define("damienmortini-awards", class extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
      <style>
        @import "src/awards/index.css";
      </style>
      <h2>Awards</h2>
      <ul></ul>
    `;
    this.querySelector("style").addEventListener("load", () => {
      this.dispatchEvent(new Event("load"));
    });

    API.getData().then((data) => {
      const ul = this.querySelector("ul");
      for (const award of data.awards) {
        ul.innerHTML += `
          <li>
            <a href="${award["Link"]}" target="_blank">${new Date(award["Date"]).getFullYear()} – <b>${award["Name"]}</b> – ${award["Project"][0]["Client"]}: ${award["Project"][0]["Name"]}</a>
          </li>
        `;
      }
    });
  }
});
