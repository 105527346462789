import API from "../API.js";

window.customElements.define("damienmortini-projects", class extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `
      <style>
        @import "src/projects/index.css";
      </style>
      <h2>Featured Works</h2>
      <ul></ul>
    `;
    this.querySelector("style").addEventListener("load", () => {
      this.dispatchEvent(new Event("load"));
    });

    API.getData().then((data) => {
      const ul = this.querySelector("ul");
      for (const project of data.projects) {
        if (!project["Featured"]) {
          continue;
        }
        const li = document.createElement("li");
        li.innerHTML = `
          <p>
            <b>${project["Client"]}:</b> ${project["Name"]} <span class="collaborators">${project["Collaborators"] ? `<span class="faded">with</span> <a href="${project["Collaborators"][0]["Website"]}" target="_blank">${project["Collaborators"][0]["Name"]}</a>` : ""}</span>
          </p>
          <p>
            ${project["Link"] ? `<a href="${project["Link"]}" target="_blank">See Project</a>` : ""}
            ${project["Case Study"] ? `<a href="${project["Case Study"]}" target="_blank">Case Study</a>` : ""}
          </p>
        `;


        const medias = project["Medias"];
        if (medias) {
          const aside = document.createElement("aside");
          const changeMedia = (e) => {
            e.preventDefault();
            const id = Math.floor(e.touches[0].clientX / window.innerWidth * medias.length);
            if (id >= 0 && id < medias.length) {
              aside.children[id].classList.add("hovered");
              this.dispatchEvent(new CustomEvent("mediachange", {
                detail: medias[medias.length - id - 1],
              }));
            }
          };
          aside.addEventListener("touchstart", (e) => {
            this.classList.add("fade");
            changeMedia(e);
          });
          aside.addEventListener("touchmove", changeMedia);
          window.addEventListener("touchend", (e) => {
            this.classList.remove("fade");
            this.dispatchEvent(new CustomEvent("mediachange", {
              detail: null,
            }));
          });
          for (let index = medias.length - 1; index >= 0; index--) {
            const media = medias[index];
            const div = document.createElement("div");
            div.style.backgroundImage = `url("${media.thumbnails.small.url}")`;
            aside.appendChild(div);
            div.addEventListener("mouseenter", (e) => {
              div.classList.add("hovered");
              div.classList.add("hover");
              this.dispatchEvent(new CustomEvent("mediachange", {
                detail: media,
              }));
            });
            div.addEventListener("mouseleave", () => {
              div.classList.remove("hover");
              this.dispatchEvent(new CustomEvent("mediachange", {
                detail: null,
              }));
            });
          }
          li.appendChild(aside);
        }
        ul.appendChild(li);
      }

      //   let lastTimeChange = 0;
      //   for (const [i, li] of [...ul.querySelectorAll("li")].entries()) {
      //     li.addEventListener("mousemove", () => {
      //       if(!this._backgroundMediaElement || performance.now() - lastTimeChange < 400) {
      //         return;
      //       }
      //       const medias = data.projects[i]["Medias"];
      //       if(!medias) {
      //         return;
      //       }
      //       lastTimeChange = performance.now();
      //       this._backgroundMediaElement.src = medias[Math.floor(Math.random() * medias.length)].url;
      //     });
      //   }
    });
  }

  set backgroundMediaElement(value) {
    this._backgroundMediaElement = value;
  }
});
